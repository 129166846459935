import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IProductMappedItem, ProductLogicService } from '@app/logic/products';
import { PRODUCT_SEARCH_TYPE_ENUM, PRODUCT_TYPE_ENUM, ProductSearchTypeEnumId, UNIT_OF_MEASURE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import { IProductCatalogSearch } from '@app/core/services/user-cache/user-cache-areas';
import { IProductDto } from '@app/logic/products/interfaces/i.product.dto';
import { ManageBundleDialogComponent } from './product-catalogue-item-dialog/manage-bundle-dialog.component';
import { ManageProductDialogComponent } from './product-catalogue-item-dialog/manage-product-dialog.component';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ProductPermissions } from '@app/core/permissions';
import { TableComponent, isNullOrWhiteSpace } from 'cb-hub-lib';
import { ToastService } from '@app/core/services/toast/toast.service';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { cloneDeep } from 'lodash';
import { IProductSearchDto } from '@app/logic/products/i.product-search.dto';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { CheckboxComponent } from '@app/shared/components/forms/checkbox/checkbox.component';

@Component({
    selector: 'cb-product-catalogue-tab',
    templateUrl: './product-catalogue-tab.component.html',
    styleUrls: ['./product-catalogue-tab.component.scss'],
    providers: [NavigationService]
})
export class ProductCatalogueTabComponent implements OnInit, AfterContentChecked {
    public uom = UNIT_OF_MEASURE_ENUM.toLookup();
    public productTypes = PRODUCT_TYPE_ENUM.toLookup();
    public productSearchType: IEnumLookup<ProductSearchTypeEnumId>[];
    public productSearchTypeText = '';
    public currentPage: number;
    public searchResults: IProductSearchDto[] = [];
    public searchIsLoading = false;
    public orderResultsSort = '';
    public filterChips: any[];
    public selectedCategory: number[];
    @ViewChild('isOnlyNullRatedComponent', { static: true }) public isOnlyNullRatedComponent: CheckboxComponent;
    @ViewChild('isActiveComponent', { static: true }) public isActiveComponent: CheckboxComponent;

    /**
     * This will lock "activeOnly" to whatever you set it to (true or false).
     * Remember, if included at all, "activeOnly" will become locked.
     * Leaving it out completely will allow you to change
     * the value of "activeOnly" with the checkbox and pill.
     */
    @Input() public activeOnly: boolean;
    @Input() public hideCompositeItems: boolean;
    @Input() public readonly inputFindDialogMode = false;
    @Input() public includeCategories: boolean;

    @Input() public readonly restrictedCategories: number[];
    @Input() public readonly hasRestrictedCategory: boolean;

    @Output() public readonly productSelected = new EventEmitter<IProductDto>();

    @ViewChild('infiniteScrollTable', { static: false }) public infiniteScrollTable: TableComponent;

    public cachedSelectedCategories: number[];
    public allowedSearchTypes: ProductSearchTypeEnumId[];
    public includeNullRated: boolean;
    public isFetching$ = new BehaviorSubject<boolean>(false);

    public get userCacheItem(): UserCacheItem<IProductCatalogSearch> {
        return this.userCacheService.productCatalogSearch;
    }

    public readonly queryUpdate = new Subject();
    public subscriptions$ = new Subscription();

    constructor(
        public readonly navigationService: NavigationService,
        public readonly toastService: ToastService,
        public readonly productLogicService: ProductLogicService,
        public readonly userCacheService: UserCacheService,
        public readonly productPermissions: ProductPermissions,
        private readonly cbDialogService: CbDialogService,
        private readonly cdRef: ChangeDetectorRef
    ) { }

    public ngOnInit(): void {
        this.userCacheItem.init().then(() => {
            if (this.hasRestrictedCategory) {
                this.cachedSelectedCategories = this.restrictedCategories || [];
            } else {
                this.cachedSelectedCategories = this.userCacheItem.silentData.selectedCategories || [];
            }
            this.updateFilterChips();
        });

        this.loadEnums();
    }

    public fetchSearchResults(): Observable<ISearchResult<IProductSearchDto>> {
        const params = this.getSearchParams();
        return this.productLogicService
            .$getSearchList(params);
    }

    public ngAfterContentChecked(): void {
        this.cdRef.detectChanges();
    }

    public viewProduct = (product: IProductDto, event: MouseEvent): void => {
        window.sessionStorage.setItem('product_catalog_scroll', `${window.pageYOffset}`);
        this.navigationService.navigate([`view-product/${product.id}/details`], event);
    };

    public selectProduct(product: IProductDto): void {
        this.productSelected.emit(product);
    }

    private openEditDialog(mappedItem: IProductMappedItem): void {
        const dialog = mappedItem.productType === PRODUCT_TYPE_ENUM.Bundle ?
            ManageBundleDialogComponent :
            ManageProductDialogComponent;
        this.cbDialogService.open(
            dialog,
            {
                data: {
                    mappedItem
                },
                fullWidth: true,
                minWidth: '70%',
            }
        )
            .afterClosed()
            .subOnce({
                next: this.handleEditItem
            });
    }

    public openCreateDialog = (mappedItem: IProductMappedItem): void => {
        const dialog = mappedItem.productType === PRODUCT_TYPE_ENUM.Bundle ?
            ManageBundleDialogComponent :
            ManageProductDialogComponent;
        this.cbDialogService.open(
            dialog,
            {
                data: {
                    mappedItem
                },
                fullWidth: true,
                minWidth: '70%',
            }
        )
            .afterClosed()
            .subOnce({
                next: this.handleNewItem
            });
    };

    public handleEditItem = (item: any): void => {
        if (item) {
            this.searchResults.forEach(sr => {
                if (sr.id === item.id) {
                    this.updateSearchResults(sr, item);
                }
            });
        }
    };

    public handleNewItem = (item: any): void => {
        if (item) {
            this.viewProduct(item, undefined);
        }
    };

    private updateSearchResults(sr: any, item: any): void {
        Object.assign(sr, item);
        sr.uomDisplay = UNIT_OF_MEASURE_ENUM[item.uom];
        sr.mainCategory = item.categoryPath[0].label;
    }

    public openCreate(type: number, event: Event): void {
        const mappedItem = this.productLogicService.$createMappedItem({
            productType: type,
            isActive: true,
            status: 0
        });
        this.openCreateDialog(mappedItem);
    }

    public openEdit = (element: IProductSearchDto): void => {
        this.productLogicService.$getItem(element.id).subOnce({
            next: this.handleGetFullProduct
        });
    };

    public handleGetFullProduct = (product: IProductDto): void => {
        const mappedItem = this.productLogicService
            .$createMappedItem(product);
        this.openEditDialog(mappedItem);
    };

    public exportToCsv(): void {
        this.productLogicService.exportToCsv().subOnce();
    }

    public performSearch = (): void => {
        if (this.userCacheItem.silentData.query) {
            this.orderResultsSort = '';
        } else {
            this.orderResultsSort = 'name';
        }
        this.updateFilterChips();
        this.queryUpdate.next(null);
    };

    public setSelectedCategory = (categoryParam: { selectedCategories: any[]; category: any }): void => {
        const { selectedCategories, category } = categoryParam;
        this.userCacheItem.promise.then(() => {
            const newCategories = cloneDeep(selectedCategories.filter(x => x !== undefined));
            this.selectedCategory = newCategories;
            const newCatId = category ? category.id as number : undefined;
            const copyData = this.userCacheItem.copyData();

            if (
                JSON.stringify(copyData.selectedCategories) !== JSON.stringify(newCategories)
                || copyData.selectedCategoryId !== newCatId
            ) {
                this.userCacheItem.data.selectedCategories = newCategories;
                this.userCacheItem.data.selectedCategoryId = newCatId;
                this.performSearch();
            }
        });
    };

    public canExportSearchResults(): boolean {
        return this.productPermissions.canExportSearchResults() && !this.inputFindDialogMode;
    }

    public canCreateProduct(): boolean {
        return this.productPermissions.canCreate() && !this.inputFindDialogMode;
    }

    public readonly removeChip = (chip): void => {
        // Checkboxes break when:
        // A) Value is undefined
        // B) Value is altered programmatically
        // Workaround is to set the value of the underlying checkbox component
        if (chip.id === 'nullRated') {
            this.isOnlyNullRatedComponent.value = false;
        } else if (chip.id === 'activeProducts') {
            this.isActiveComponent.value = false;
        } else if (chip.id === 'st') {
            this.userCacheItem.data.strictSearch = false;
        }
        else {
            // Can set non-checkbox fields to undefined
            this.userCacheItem.data[chip.id] = undefined;
        }
        this.filterChips = this.filterChips.filter(x => x.id !== chip.id);
        this.filterChips[chip.id] = undefined;
        this.performSearch();
    };

    public readonly toggleNullRated = (value?: boolean): void => {
        if (value !== undefined) {
            this.userCacheItem.data.nullRated = value;
        } else {
            this.userCacheItem.data.nullRated = !this.userCacheItem.data.nullRated;
        }
        this.performSearch();
    };

    public readonly toggleActiveOnly = (value?: boolean): void => {
        if (value !== undefined) {
            this.userCacheItem.data.activeProducts = value;
        } else {
            this.userCacheItem.data.activeProducts = !this.userCacheItem.data.activeProducts;
        }
        this.performSearch();
    };

    public readonly toggleStrictSearch = (value?: boolean): void => {
        if (value !== undefined) {
            this.userCacheItem.data.strictSearch = value;
        } else {
            this.userCacheItem.data.strictSearch = !this.userCacheItem.data.strictSearch;
        }
        this.performSearch();
    };

    public getSearchParams(): any {
        const query = isNullOrWhiteSpace(this.userCacheService?.productCatalogSearch?.silentData?.query)
            ? undefined
            : this.userCacheService?.productCatalogSearch?.silentData?.query;
        return {
            activeOnly: this.getActiveOnly(),
            cat: this.getCategoryId(),
            nullRated: this.getNullRated(),
            nullRatedFuture: this.userCacheService?.productCatalogSearch?.silentData?.nullRatedFuture,
            hideCompositeItems: this.hideCompositeItems,
            includeCategories: this.includeCategories,
            query,
            t: this.userCacheService?.productCatalogSearch?.silentData?.productSearchTypeId,
            currentPage: this.currentPage,
            st: this.userCacheService.productCatalogSearch?.data?.strictSearch,
            sp: true
        };

    }

    private loadEnums(): void {
        this.productSearchType = PRODUCT_SEARCH_TYPE_ENUM.toLookup()
            .filter(x => this.allowedSearchTypes ? this.allowedSearchTypes.includes(x.id) : true);
        this.userCacheItem.promise.then(() => {
            this.userCacheItem.data.productSearchTypeId
                = this.getValidSearchType(this.userCacheItem.silentData.productSearchTypeId);
            this.productSearchTypeText = PRODUCT_SEARCH_TYPE_ENUM[this.userCacheItem.data.productSearchTypeId];
        });
    }

    public productSearchTypeChanged(): void {
        this.productSearchTypeText = PRODUCT_SEARCH_TYPE_ENUM[this.userCacheItem.data.productSearchTypeId];
        this.performSearch();
    }

    private updateFilterChips(): void {
        this.filterChips = [];
        if (this.userCacheItem.silentData.query !== undefined
            && this.userCacheItem.silentData.query !== '') {
            this.filterChips.push({ id: 'query', text: `Query - ${this.userCacheItem.silentData.query}` });
        }

        if (this.userCacheItem.silentData.productSearchTypeId > 0) {
            this.filterChips.push({ id: 'productSearchTypeId', text: `Filter Type - ${this.productSearchTypeText}` });
        }

        if (this.userCacheItem.silentData.strictSearch) {
            this.filterChips.push({ id: 'st', text: 'Strict Search' });
        }

        const activeOnly = this.getActiveOnly();
        // Do not create the filter chip for active only if it is locked
        if (activeOnly && !this.activeOnlyIsLocked()) {
            this.filterChips.push({ id: 'activeProducts', text: 'Active Only' });
        }

        if (this.getNullRated()) {
            this.filterChips.push({ id: 'nullRated', text: 'Only Null Rated' });

            if (this.userCacheItem.silentData.nullRatedFuture) {
                this.filterChips.push({ id: 'nullRatedFuture', text: 'Only Null Rated With Future Rate' });
            }
        }
    }

    private getValidSearchType(type: ProductSearchTypeEnumId): ProductSearchTypeEnumId {
        if (this.allowedSearchTypes) {
            return this.allowedSearchTypes.includes(type) ? type : this.allowedSearchTypes[0];
        }
        return type;
    }

    private getActiveOnly(defaultVal = this.userCacheItem?.silentData?.activeProducts): boolean {
        return this.activeOnlyIsLocked() ? this.activeOnly : defaultVal;
    }

    private getNullRated(defaultVal = this.userCacheItem?.silentData?.nullRated): boolean {
        return this.nullRatedIsLocked() ? this.includeNullRated : defaultVal;
    }

    private getCategoryId(): number {
        return this.userCacheItem?.silentData?.selectedCategoryId;
    }

    /**
     * This is when you pass in [activeOnly]="true" or [activeOnly]="false"
     * Which will not allow you to change the value of activeOnly.
     * */
    public activeOnlyIsLocked(): boolean {
        return this.activeOnly !== undefined;
    }

    public nullRatedIsLocked(): boolean {
        return this.includeNullRated !== undefined;
    }

    public canPerformSearch(): boolean {
        return this.userCacheService.productCatalogSearch.data.query?.length > 0;
    }
}
