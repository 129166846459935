import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ProductLogicService } from '@app/logic/products';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { getBaseFormComponentDirectiveProvider } from '../../base-form-component';
import { BaseInputFindDirective } from '../../input-find/base-input-find.directive';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ProductInputFindDialogComponent } from './product-input-find-dialog/product-input-find-dialog.component';
import { IProductOfferingDto } from '@classictechsolutions/hubapi-transpiled-enums';


@Component({
    selector: 'cb-product-input-find',
    templateUrl: '../../input-find/base-input-find.directive.html',
    styleUrls: ['../../input-find/base-input-find.directive.scss'],
    providers: [
        getBaseFormComponentDirectiveProvider(ProductInputFindComponent),
        ProductLogicService,
        provideParentForm()
    ]
})
export class ProductInputFindComponent extends BaseInputFindDirective {

    @Input() public readonly activeOnly: boolean;
    @Input() public readonly hideCompositeItems: boolean;
    @Input() public readonly restrictedCategories: number[];
    @Input() public readonly hasRestrictedCategory: boolean;

    @Output() public readonly productSelected = new EventEmitter<IProductOfferingDto>();

    protected _myDialog = ProductInputFindDialogComponent;
    constructor(
        public readonly dialogRef: MatDialog,
        public readonly router: Router,
        public readonly productLogicService: ProductLogicService,
        public readonly navigationService: NavigationService,
        private readonly cbDialog: CbDialogService,
    ) {
        super();
    }

    public clear(): void {
        super.clear();
        this.text = '';
    }

    public openSearchDialog = (): void => {
        this.cbDialog.open(
            this._myDialog,
            {
                minWidth: '95%',
                minHeight: '95%',
                data: {
                    extraSearchParams: this.searchParams,
                    activeOnly: this.activeOnly,
                    hideCompositeItems: this.hideCompositeItems,
                    restrictedCategories: this.restrictedCategories,
                    hasRestrictedCategory: this.hasRestrictedCategory
                },
            }
        )
            .afterClosed()
            .subOnce((result: IProductOfferingDto | false) => {
                if (result) {
                    this.productSelected.emit(result);
                }
            });
    };
}
